body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
	height: 10px;
	width: 10px;
}

::-webkit-scrollbar-track {
	background-color: transparent;
}

::-webkit-scrollbar-thumb {
	background-clip: content-box;
	background-color: #747474;
	border: 2px solid transparent;
	border-radius: 5px;
}
